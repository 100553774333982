.dashboard {

    @include media-breakpoint-down(xs) { 
        h1 {
            display: none;
        } 
        .btn-create-costumer {
            text-align: center !important
        } 

     }

  
}
  