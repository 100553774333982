.locals {
	ul,
	li {
		padding: 0;
		margin: 0;
		list-style: none;
	}
	ul {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin: 0 32px 20px 0;
	}

	button {
		width: 30px;
		height: 30px;
		border-radius: 0;
		background: #fff;
		border: solid 1px #ccc;
		border-left: none;
		&.active {
			background: $blue;
		}
	}

	li {
		&:first-child {
			button {
				border-left: solid 1px #ccc;
			}
		}
	}
}
