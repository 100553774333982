.costumer {


  
}

.costumer-view {

    @include media-breakpoint-down(xs) { 

        
        .nav-tabs {
            border : none; 

            &.nav {
                flex-direction: column; 
            }

            .nav-item {
                margin-bottom: 0;
                border:solid 1px #d1d4d7;
                border-bottom : none;
                a{
                    text-align: center;

                    &.active {
                        border :none;
                        font-weight: bold;
                    }
                } 

            }

        }

        .tab-content {
            border : none; 
            .tab-pane {
                padding: 0;
            }
        }
    } 

}
  