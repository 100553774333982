// core overrides

$border-color: $gray-300;

// Navbar

$navbar-brand-width: 200px;
$navbar-brand-logo-size: 80px auto;

// Sidebar

$sidebar-width: 250px;
$mobile-sidebar-width: 250px;

// Sidebar Navigation

$sidebar-bg: $gray-800 !default;
$sidebar-nav-link-hover-bg: darken($sidebar-bg, 5%);
$sidebar-nav-link-active-bg: darken($sidebar-bg, 5%);
$sidebar-nav-dropdown-bg: darken($sidebar-bg, 3%);

// Breadcrumb

$breadcrumb-borders: 0;
