.icon-all {
	color: blue;
}

.icon-pending {
	color: fuchsia;
}

.icon-modifications{
	color: #EAC7E7;
}

.icon-valid {
	color: #4dbd74;
}

.icon-cancellation-asked {
	color: grey;
}

.icon-cancelled {
	color: black;
}

.icon-invalid {
	color: #660;
}

.icon-treatment-pending {
	color: #ff882b;
}

.icon-unpayed {
	color: #045C60;
}

.icon-updated{
	color: #8b4513;
}
.icon-archived {
	color: #ff0c00;
}


.icon-contract {
	color: #4dbd74;
}


.icon-account {
	color: #f39c12;
}