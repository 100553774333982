.progress-xs {
  height: 4px;
}

.progress-sm {
  height: 8px;
}

// White progress bar
.progress-white {
  background-color: rgba(255,255,255,.2) !important;
  .progress-bar {
    background-color: #fff;
  }
}
