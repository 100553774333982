// .aside-menu-fixed {
//     .main,
//     .aside-menu-fixed .app-footer {
//         margin-right: 0;
//     }

//     .aside-menu {
//         top: 95px;
//     }
// }

.aside-menu-hidden {
    .aside-menu {
        margin-right: -400px !important;
    }
}

.aside-menu.filters {
    box-shadow: 10px 10px 33px #000;
    border: none;
    display: flex;
    flex-direction: column;
    width: 280px;
    top: 117px;

    .aside-content {
        padding: 18px;
        height: calc(100vh - 170px);
        overflow: auto;
        width: 100%;
    }

    .aside-footer {
        display: flex;
        align-items: stretch;
        width: 100%;
        height: 50px;
    }

    .filter-title {
        color: #fff;
        display: flex;
        width: 100%;
        background: $primary;
        flex-direction: row;
        height: 55px;
        align-items: center;
        border: none;

        i.fa-filter {
            margin: 0 16px 0 18px;
        }

        i.fa-remove {
            margin: 0 24px 0 0;
        }

        span {
            flex: 1;
            text-align: center;
        }

        &:hover {
            text-decoration: none;
            color: #fff;
        }
    }

    .date {
        display: flex;
        flex-direction: row;
        flex: 1;
        margin-top: 25px;

        .form-group {
            flex: 1;
            margin: 0;

            .form-control-label {
                text-transform: none;
                font-weight: 300;
                text-align: center;
                display: block;
            }

            .form-control {
                width: 100px;
                padding: 0;
                text-align: center;
            }
        }
    }

    button[type='submit'].btn {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        flex: 1;
        width: 100%;
        display: flex;
        border-radius: 0;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}

@include media-breakpoint-down(lg) {
    .main {
        margin-right: 0 !important;
    }
    .aside-menu.filters {
        display: block;
        box-shadow: none;
        width: 100%;
        position: fixed;
        z-index: 1000;
        top: 65px;
        bottom: 0;
    }

    .aside-menu-hidden {
        .aside-menu.filters {
            display: none;
        }
    }
}
